
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { ISupplier, ISupplierListRequest } from "@/types/supplier";
import {
  ISupplierStaff,
  ISupplierStaffCreateRequest,
  ISupplierStaffUpdateRequest
} from "@/types/supplier_staff";
import { SupplierStaffRequest } from "@/models/supplier_staff";

@Component({})
export default class extends Vue {
  //supplier_staff
  @Action("supplier_staff/adminGet")
  public getSupplierStaff!: (supplier_staff_id: number) => void;

  @Action("supplier_staff/adminCreate")
  public create!: (params: ISupplierStaffCreateRequest) => boolean;

  @Action("supplier_staff/adminUpdate")
  public update!: (data: {
    supplier_staff_id: number;
    params: ISupplierStaffUpdateRequest;
  }) => boolean;

  @Action("supplier_staff/adminDelete")
  public deleteSupplierStaff!: (supplier_staff_id: number) => boolean;

  @Getter("supplier_staff/single")
  public supplier_staff!: ISupplierStaff;

  @Mutation("supplier_staff/clear")
  public clear!: () => void;

  //supplier
  @Action("supplier/adminGetList")
  public getSuppliers!: (params: ISupplierListRequest) => boolean;

  @Getter("supplier/selectItem")
  public supplierList!: ISelectItem[];

  @Getter("supplier/find")
  public findSupplier!: (id: number) => ISupplier;

  @Mutation("supplier/clear")
  public clearSupplier!: () => void;

  public supplier_staff_id = 0;
  public supplier_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;

  public valid = true;
  public lazy = false;

  public rules = {
    name: [(v: string) => !!v || "担当者名は必須です"],
    kana: [
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶﾀｶﾅで入力してください"
    ],
    email: [
      (v: string) =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "正しいメールアドレスを入力してください"
    ],
    tel: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    supplier_id: [(v: number) => !!v || "サプライヤは必須です"]
  };

  public params: ISupplierStaffUpdateRequest = new SupplierStaffRequest();

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.supplier_id = this.params.supplier_id = Number(
      this.$route.params.supplier_id
    ); // 新規登録時存在
    this.supplier_staff_id = Number(this.$route.params.supplier_staff_id); // 編集時存在
    this.clear();
    // 編集時
    if (this.supplier_staff_id) {
      await this.getSupplierStaff(this.supplier_staff_id);
      this.setDefault();
      this.clearSupplier();
    }

    await Promise.all([this.getSuppliers({ per_page: 0 })]);

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.name = this.supplier_staff.name;
    this.params.kana = this.supplier_staff.kana;
    this.params.email = this.supplier_staff.email;
    this.params.tel = this.supplier_staff.tel;
    this.params.supplier_id =
      this.supplier_id || this.supplier_staff.supplier_id;
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.supplier_staff_id) {
      result = await this.update({
        supplier_staff_id: this.supplier_staff_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteSupplierStaff(this.supplier_staff_id)) {
      this.$router.go(-1);
    }
  }
}
